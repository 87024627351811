import { GridBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import {
  storyblokEditable,
  StoryblokComponent
} from "@storyblok/react";

import { GridProps } from "./types";
import { getColumns } from "./utils";

const Grid: React.FC<GridProps> = ({ blok }) => {
  const {
    columns,
    columnsNumberDesktop,
    columnsNumberTablet,
    columnsNumberMobile,
    itemsAlignmentDesktop,
    itemsAlignmentTablet,
    itemsAlignmentMobile,
    itemsJustificationDesktop,
    itemsJustificationTablet,
    itemsJustificationMobile,
    gap,
    gapRow,
    gapColumn
  } = blok;

  const itemsAlignment: string[] = [
    itemsAlignmentMobile || "",
    itemsAlignmentTablet || "",
    itemsAlignmentDesktop || ""
  ];

  const itemsJustification: string[] = [
    itemsJustificationMobile || "",
    itemsJustificationTablet || "",
    itemsJustificationDesktop || ""
  ];

  const desktopColumns = columnsNumberDesktop
    ? getColumns(columnsNumberDesktop)
    : "1fr";

  const tabletColumns = columnsNumberTablet
    ? getColumns(columnsNumberTablet)
    : desktopColumns;

  const mobileColumns = columnsNumberMobile
    ? getColumns(columnsNumberMobile)
    : "1fr";

  const gapConditional =
    !gapColumn && !gapRow ? ["2rem", "", "8.75rem"] : "";

  return columns?.length ? (
    <GridBox
      {...storyblokEditable(blok)}
      gridTemplateColumns={[
        mobileColumns,
        tabletColumns,
        desktopColumns
      ]}
      alignItems={itemsAlignment}
      justifyItems={itemsJustification}
      gridGap={gap ? gap.split(" ") : gapConditional}
      gridRowGap={gapRow && !gap ? gapRow.split(" ") : ""}
      gridColumnGap={gapColumn && !gap ? gapColumn.split(" ") : ""}
    >
      {columns.map((nestedBlok) => {
        const { _uid: key } = nestedBlok;

        return (
          <div {...{ key, ...storyblokEditable(nestedBlok) }}>
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
            />
          </div>
        );
      })}
    </GridBox>
  ) : null;
};

export default Grid;
